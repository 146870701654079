<template>
    <el-main>
        <el-button class="mb20" type="primary" size="small" @click="$router.push('/extension/crossWebsite/addShortVideo')">+
            添加短视频</el-button>
        <el-form class="el-form-search" label-width="110px">
            <el-form-item label="短视频标题：">
                <el-input size="small" placeholder="请输入短视频标题" v-model="searchForm.nickname"></el-input>
            </el-form-item>
            <el-form-item label="短视频分类：">
                <el-select v-model="searchForm.classify_id" size="small">
                    <el-option v-for="(item, index) in classify_list" :key="index" :label="item.classify_name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否显示：">
                <el-select v-model="searchForm.is_show" size="small">
                    <el-option v-for="(item, index) in showList" :key="index" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button type="primary" size="small" @click="getList">搜索</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column label="排序" align="center" width="100px">
                <template v-slot="{ row }">
                    <el-input @blur="updateVideoItem(item)" size="small" type="number" :min="0"
                        v-model="row.sort"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="短视频封面" align="center">
                <template v-slot="{ row }">
                    <el-image style="width: 75px; height: 40px" :src="row.cover_picture"
                        :preview-src-list="[row.cover_picture]"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="短视频标题" align="center"></el-table-column>
            <el-table-column prop="classify_name" label="短视频分类" align="center"></el-table-column>
            <el-table-column prop="source" label="短视频来源" align="center"></el-table-column>
            <el-table-column label="短视频二维码" align="center">
                <template v-slot="{ row }">
                    <el-image style="width: 75px; height: 40px" :src="row.qrcode"
                        :preview-src-list="[row.qrcode]"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="true_view" label="真实查看数量" align="center"></el-table-column>
            <el-table-column prop="true_like" label="真实点赞数量" align="center"></el-table-column>
            <el-table-column label="显示" align="center">
                <template v-slot="{ row }">
                    <el-switch @change="updateVideoItem(row)" v-model="row.is_show" :active-value="1"
                        :inactive-value="0"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" @click="toEditVideo(row)">编辑</el-button>
                    <el-button type="text" @click="deleteVideo(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            searchForm: {
                classify_id: 0,
                title: '',
                is_show: 0,
            },
            showList: [
                { id: 0, name: '全部' },
                { id: 2, name: '显示' },
                { id: 1, name: '不显示' },
            ],
            classify_list: [{ id: 0, classify_name: '全部' }],
            page: 1,
            rows: 10,
            total: 0,
            dataList: [],
        };
    },
    created () {
        this.getClassifyList();
        this.getList();
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.rows = val;
            } else {
                this.page = val;
            }
            this.getList();
        },
        getClassifyList () {
            this.$axios.post(this.$api.Tikmall.getVideoClassifyList).then(res => {
                if (res.code === 0) {
                    this.classify_list.push(...res.result);
                }
            });
        },
        getList (status) {
            if (status) this.page = 1;
            let obj = {
                page: this.page,
                rows: this.rows,
            };
            if (this.searchForm.classify_id) obj.classify_id = this.searchForm.classify_id;
            if (this.searchForm.title) obj.title = this.searchForm.title;
            if (this.searchForm.is_show) obj.is_show = this.searchForm.is_show;
            this.$axios.post(this.$api.Tikmall.videoList, obj).then(res => {
                if (res.code === 0) {
                    this.dataList = res.result.list;
                    this.total = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        cancelSearch () {
            this.searchForm = {
                classify_id: 0,
                title: '',
                is_show: 0,
            };
            this.getList();
        },
        updateVideoItem (data) {
            console.log(data);
            this.$axios
                .post(this.$api.Tikmall.quickEditVideoInfo, {
                    id: data.id,
                    sort: data.sort,
                    is_show: data.is_show,
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message.success('设置成功');
                    } else {
                        this.$message.error(res.msg);
                        this.getList();
                    }
                });
        },
        deleteVideo (data) {
            this.$confirm('此操作将永久删除该短视频, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.Tikmall.delVideoInfo, {
                        id: data.id,
                    });
                })
                .then(res => {
                    if (res.code === 0) {
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        // 跳转到编辑短视频
        toEditVideo (data) {
            this.$router.push(`/extension/crossWebsite/editShortVideo?id=${data.id}`);
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;

    .mb20 {
        margin-bottom: 20px;
    }

    .el-table {
        /deep/ .el-input__inner {
            border-color: #fff;
            text-align: center;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }
    }
}</style>
